<template>
  <div class="settings-form">
    <div class="title-wrap">
      <panel-title>{{ 'form.form' | translate }}</panel-title>
      <i @click="$emit('update', 'form')" class="material-icons">{{
        accordeonItem === 'form' ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
      }}</i>
    </div>
    <template v-if="uiSchema.length > 0">
      <div class="content-wrap" v-if="accordeonItem === 'form'">
        <!-- <talmundo-color-picker
        :label="$t('labels.textColor')"
        @change="changeFormColor($event)"
        v-model="color"
        />-->
        <label>{{ 'labels.textAlignment' | translate }}</label>
        <text-alignment @alignmentChanged="changeTextAlignment" :initialValue="alignment" />
        <talmundo-input
          :twolines="true"
          type="input"
          id="FinishMessage"
          label="finish message"
          :value="value"
          @changeValue="changeType($event, 'finalMessage')"
          @updateValue="updateValue($event, 'finalMessage')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import PanelTitle from '@forms/components/settings-panel/PanelTitle.vue';
import TextAlignment from '@admin/shared/components/TextAlignment.vue';
import { mapGetters } from 'vuex';

export default {
  props: {
    accordeonItem: {
      type: String
    }
  },
  components: {
    PanelTitle,
    TextAlignment
  },
  mounted() {
    this.setValue();
  },
  data() {
    return {
      color: '#000',
      value: '',
      initialValue: this.$t('forms.formSubmitted')
    };
  },
  methods: {
    setValue() {
      if (!this.schema.data) {
        this.$store.commit('formBuilder/addFormFinalMessage', {
          type: 'finalMessage',
          value: this.initialValue
        });
      }
      this.value =
        this.schema && this.schema.data
          ? this.schema.data.finalMessage.replace(/&amp;/gi, '&').replace(/<\/?[^>]+(>|$)/g, '')
          : '';
    },
    changeType(value, type) {
      this.value = value;
    },
    updateValue(value, type) {
      this.$store.commit('formBuilder/editForm', {
        type: type,
        value: value
      });
    },
    changeTextAlignment(alignment) {
      this.$store.commit('formBuilder/changeTextAlignment', alignment);
    },
    changeFormColor(color) {
      this.$store.commit('formBuilder/changeTextColor', color);
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['alignment', 'uiSchema', 'schema'])
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
.settings-form {
  border: 1px solid rgba(2, 28, 44, 0.15);
  border-radius: 4px;

  label {
    display: block;
  }

  .content-wrap {
    padding: 24px;
  }

  .title-wrap {
    padding: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    i {
      cursor: pointer;
      margin-left: auto;
    }
  }

  hr {
    margin: 0;
  }
}
</style>
