<template>
  <div>
    <talmundo-select
      id="showTypeChoice"
      :label="$t('webhook.common.type')"
      v-model="getType"
      :items="getItems"
      @change="changeType($event, 'type')"
    />
    <talmundo-switch
      id="showEnableOtherOption"
      :label="$t('form.otherOption')"
      v-model="getEnableOtherOption"
      @change="updateOtherOption($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

export default {
  data() {
    return {
      maxRating: 10,
      open: false
    };
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedQuestion', 'requiredQuestion']),
    getType() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        return this.selectedQuestion.schema.data.type;
      }
      return true;
    },
    getEnableOtherOption() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        return this.selectedQuestion.schema.data.enableOtherOption;
      }
      return true;
    },
    getItems() {
      return [
        { key: 'radio', label: this.$t('form.radio'), value: 'radio' },
        { key: 'checkbox', label: this.$t('form.checkbox'), value: 'checkbox' },
        { key: 'select', label: this.$t('form.select'), value: 'select' }
      ];
    }
  },
  methods: {
    changeType(value, type) {
      this.$store.commit('formBuilder/editFormQuestion', {
        type: type,
        value: value
      });
      this.$store.commit('formBuilder/editFormQuestionProp', {
        type: 'type',
        value: value === 'checkbox' ? 'array' : 'string'
      });
      if (value === 'checkbox') {
        this.$store.commit('formBuilder/editFormQuestionProp', {
          type: 'items',
          value: { type: 'string', enum: this.selectedQuestion.schema.enum }
        });
        this.$store.commit('formBuilder/deleteFormQuestionProp', 'enum');
        if (this.requiredQuestion) {
          this.$store.commit('formBuilder/editFormQuestionProp', {
            type: 'minItems',
            value: 1
          });
        }
      } else {
        if (this.selectedQuestion.schema.items) {
          this.$store.commit('formBuilder/editFormQuestionProp', {
            type: 'enum',
            value: this.selectedQuestion.schema.items.enum
          });
          this.$store.commit('formBuilder/deleteFormQuestionProp', 'items');
          this.$store.commit('formBuilder/deleteFormQuestionProp', 'minItems');
        }
      }
    },
    updateOtherOption(value) {
      let type = this.selectedQuestion.schema.data.type;
      let newEnum;
      if (type === 'checkbox') {
        newEnum = cloneDeep(this.selectedQuestion.schema.items.enum);
      } else {
        newEnum = cloneDeep(this.selectedQuestion.schema.enum);
      }
      if (value) {
        newEnum.push('Other');
      } else {
        newEnum.pop();
      }
      this.$store.commit('formBuilder/editFormQuestionProp', {
        type: type === 'checkbox' ? 'items' : 'enum',
        value: type === 'checkbox' ? { type: 'string', enum: newEnum } : newEnum
      });
      this.$store.commit('formBuilder/editFormQuestion', {
        type: 'enableOtherOption',
        value: value
      });
      this.$store.commit('formBuilder/updateChoiceOther', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
.select-wrapper {
  background-color: $color-tertiary;
  width: 100%;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  position: relative;
  padding-left: 8px;
}

.material-icons {
  color: $color-midgreyblue;
  position: absolute;
  top: 8px;
  right: 8px;
}

label {
  color: $color-secondary;
  font-size: 12px;
  line-height: 40px;
}

.options {
  height: 80px;
  overflow-y: scroll;
  background-color: $color-tertiary;
  width: 100%;

  div {
    padding: 8px;
  }
}
</style>
