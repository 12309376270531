<template>
  <div>
    <talmundo-switch id="requireMinValue" label="Require min value" v-model="showMin" @change="updateMin($event)" />
    <talmundo-input
      v-if="showMin"
      :twolines="true"
      type="number"
      id="QuestionMinValue"
      label="Min Value"
      :value="minValue"
      v-model="minValue"
      @changeValue="changeType($event, 'minValue')"
      @updateValue="updateValue($event, 'minValue')"
    />
    <talmundo-switch id="requireMaxValue" label="Require max value" v-model="showMax" @change="updateMax($event)" />
    <talmundo-input
      v-if="showMax"
      :twolines="true"
      type="number"
      id="QuestionMaxValue"
      label="Max Value"
      :value="maxValue"
      v-model="maxValue"
      @changeValue="changeType($event, 'maxValue')"
      @updateValue="updateValue($event, 'maxValue')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      showMin: false,
      showMax: false,
      minValue: '',
      maxValue: '',
      value: ''
    };
  },
  mounted() {
    this.setValue();
  },
  watch: {
    selectedQuestion() {
      this.setValue();
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedQuestion'])
  },
  methods: {
    setValue() {
      this.showMin = this.selectedQuestion.schema.data.showMin;
      this.minValue =
        this.selectedQuestion && this.selectedQuestion.schema ? `${this.selectedQuestion.schema.data.minValue}` : '';
      this.showMax = this.selectedQuestion.schema.data.showMax;
      this.maxValue =
        this.selectedQuestion && this.selectedQuestion.schema ? `${this.selectedQuestion.schema.data.maxValue}` : '';
    },
    changeType(value, type) {
      if (type == 'minValue') {
        this.minValue = value;
      } else {
        this.maxValue = value;
      }
    },
    updateValue(value, type) {
      this.$store.commit('formBuilder/editFormQuestion', {
        type: type,
        value: Number(value)
      });

      if (this.showMin && this.selectedQuestion.schema.minimum !== this.minValue) {
        this.$store.commit('formBuilder/editFormQuestionProp', {
          type: 'minimum',
          value: Number(this.minValue)
        });
      }
      if (this.showMax && this.maxValue !== this.selectedQuestion.schema.maximum) {
        this.$store.commit('formBuilder/editFormQuestionProp', {
          type: 'maximum',
          value: Number(this.maxValue)
        });
      }
    },
    updateMin(event, type) {
      this.showMin = !this.showMin;
      this.$store.commit('formBuilder/editFormQuestion', {
        type: 'showMin',
        value: this.showMin
      });
      if (this.showMin) {
        this.$store.commit('formBuilder/editFormQuestionProp', {
          type: 'minimum',
          value: Number(this.minValue)
        });
      } else {
        this.$store.commit('formBuilder/deleteFormQuestionProp', 'minimum');
      }
    },
    updateMax(event, type) {
      this.showMax = !this.showMax;
      this.$store.commit('formBuilder/editFormQuestion', {
        type: 'showMax',
        value: this.showMax
      });
      if (this.showMax) {
        this.$store.commit('formBuilder/editFormQuestionProp', {
          type: 'maximum',
          value: Number(this.maxValue)
        });
      } else {
        this.$store.commit('formBuilder/deleteFormQuestionProp', 'maximum');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
.select-wrapper {
  background-color: $color-tertiary;
  width: 100%;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  position: relative;
  padding-left: 8px;
}

.material-icons {
  color: $color-midgreyblue;
  position: absolute;
  top: 8px;
  right: 8px;
}

label {
  color: $color-secondary;
  font-size: 12px;
  line-height: 40px;
}

.options {
  height: 80px;
  overflow-y: scroll;
  background-color: $color-tertiary;
  width: 100%;

  div {
    padding: 8px;
  }
}
</style>
