<template>
  <div class="settings-panel col-3 ml-auto">
    <div :class="{ sticky: sticky }" class="panel-container">
      <settings-form class="panel" @update="updateAccordeon" :accordeonItem="accordeonItem"></settings-form>
      <settings-section class="panel" @update="updateAccordeon" :accordeonItem="accordeonItem"></settings-section>
      <settings-group class="panel" @update="updateAccordeon" :accordeonItem="accordeonItem"></settings-group>
      <settings-question class="panel" @update="updateAccordeon" :accordeonItem="accordeonItem"></settings-question>
    </div>
  </div>
</template>

<script>
import SettingsForm from '@forms/components/settings-panel/SettingsForm.vue';
import SettingsSection from '@forms/components/settings-panel/SettingsSection.vue';
import SettingsQuestion from '@forms/components/settings-panel/SettingsQuestion.vue';
import SettingsGroup from '@forms/components/settings-panel/SettingsGroup.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    SettingsForm,
    SettingsSection,
    SettingsQuestion,
    SettingsGroup
  },
  data() {
    return {
      accordeonItem: 'question',
      sticky: false
    };
  },
  methods: {
    updateAccordeon(value) {
      this.accordeonItem = this.accordeonItem === value ? '' : value;
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedItem'])
  },
  watch: {
    selectedItem: {
      immediate: true,
      handler(selectedItem) {
        if (selectedItem.questionId) {
          this.accordeonItem = 'question';
        } else if (selectedItem.groupId) {
          this.accordeonItem = 'group';
        } else if (selectedItem.sectionId) {
          this.accordeonItem = 'section';
        } else {
          this.accordeonItem = 'form';
        }
      }
    }
  },
  mounted() {
    window.onscroll = () => {
      if (window.pageYOffset > 100) {
        this.sticky = true;
      } else {
        this.sticky = false;
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.sticky {
  overflow-y: auto;
  width: 305px;
  position: fixed;
  top: 16px;
}

.panel-container {
  overflow-y: auto;
  max-height: calc(100vh - 130px);
  &.sticky {
    max-height: 95vh;
  }
}

.panel {
  margin-bottom: 24px;
}
</style>
