<template>
  <div>
    <talmundo-input
      :twolines="true"
      type="input"
      id="QuestionRegExp"
      label="Regular Expression"
      :value="pattern"
      @changeValue="changeType($event, 'pattern')"
      @updateValue="updateValue($event, 'pattern')"
    />
    <talmundo-input
      :twolines="true"
      type="input"
      id="QuestionCustomErrorMessage"
      label="Custom error message"
      :value="error"
      @changeValue="changeType($event, 'error')"
      @updateValue="updateValue($event, 'error')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      pattern: '',
      error: '',
      value: ''
    };
  },
  mounted() {
    this.setValue();
  },
  watch: {
    selectedQuestion() {
      this.setValue();
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedQuestion'])
  },
  methods: {
    setValue() {
      this.pattern =
        this.selectedQuestion && this.selectedQuestion.schema
          ? this.selectedQuestion.schema.pattern.replace(/&amp;/gi, '&').replace(/<\/?[^>]+(>|$)/g, '')
          : '';
      this.error =
        this.selectedQuestion && this.selectedQuestion.schema
          ? this.selectedQuestion.schema.data.error.replace(/&amp;/gi, '&').replace(/<\/?[^>]+(>|$)/g, '')
          : '';
    },
    changeType(value, type) {
      if (type === 'pattern') {
        this.pattern = value;
      } else {
        this.error = value;
      }
    },
    updateValue(value, type) {
      if (type === 'pattern') {
        if (this.validateRegex(value)) {
          this.$store.commit('formBuilder/editFormQuestionProp', {
            type: type,
            value: value
          });
        }
      } else {
        this.$store.commit('formBuilder/editFormQuestion', {
          type: type,
          value: value
        });
      }
    },
    validateRegex(regex) {
      let isValid = true;
      try {
        new RegExp(regex);
      } catch (e) {
        isValid = false;
      }
      return isValid;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
.select-wrapper {
  background-color: $color-tertiary;
  width: 100%;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  position: relative;
  padding-left: 8px;
}

.material-icons {
  color: $color-midgreyblue;
  position: absolute;
  top: 8px;
  right: 8px;
}

label {
  color: $color-secondary;
  font-size: 12px;
  line-height: 40px;
}

.options {
  height: 80px;
  overflow-y: scroll;
  background-color: $color-tertiary;
  width: 100%;

  div {
    padding: 8px;
  }
}
</style>
