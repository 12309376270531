<template>
  <div>
    <!-- TODO https://talmundo.atlassian.net/browse/OA-1788 -->
    <!-- <talmundo-input-number
      id="showQuestionEnum"
      label="max files"
      v-model="getMaxFiles"
      @change="changeType($event, 'maxFiles')"
      :max="maxFiles"
    /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      maxFiles: 10,
      open: false
    };
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedQuestion']),
    getFileType() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        return this.selectedQuestion.schema.data.fileType;
      }
      return true;
    },
    getMaxFiles() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        return this.selectedQuestion.schema.data.maxFiles;
      }
      return true;
    },
    getItems() {
      return [
        { key: 'pdf', label: 'pdf', value: 'pdf' },
        { key: 'jpg', label: 'jpg', value: 'jpg' },
        { key: 'doc', label: 'doc', value: 'doc' }
      ];
    }
  },
  methods: {
    changeType(value, type) {
      this.$store.commit('formBuilder/editFormQuestion', {
        type: type,
        value: value
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
.select-wrapper {
  background-color: $color-tertiary;
  width: 100%;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  position: relative;
  padding-left: 8px;
}

.material-icons {
  color: $color-midgreyblue;
  position: absolute;
  top: 8px;
  right: 8px;
}

label {
  color: $color-secondary;
  font-size: 12px;
  line-height: 40px;
}

.options {
  height: 80px;
  overflow-y: scroll;
  background-color: $color-tertiary;
  width: 100%;

  div {
    padding: 8px;
  }
}
</style>
