<template>
  <div class="settings-section" v-if="selectedGroup">
    <div class="title-wrap">
      <panel-title v-html="groupTitle"></panel-title>
      <i @click="$emit('update', 'group')" class="material-icons">{{
        accordeonItem === 'group' ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
      }}</i>
    </div>
    <div class="content-wrap" v-if="accordeonItem === 'group'">
      <talmundo-switch
        v-if="!isNotDependencyQuestion"
        id="showRequired"
        label="required"
        v-model="requiredGroup"
        @change="changeRequired($event)"
      />
      <talmundo-switch
        id="isRepeatable"
        :label="$t('forms.repeatingArea')"
        v-model="repeatable"
        @change="changeProp($event, 'repeatable')"
      />
      <talmundo-switch
        id="showQuestionTitle"
        :label="$t('labels.settingTitle')"
        v-model="showTitle"
        @change="changeVisibility($event, 'showTitle')"
      />
      <talmundo-switch
        id="showQuestionDescription"
        :label="$t('labels.settingDescription')"
        v-model="showDescription"
        @change="changeVisibility($event, 'showDescription')"
      />
      <input
        v-if="repeatable"
        id="buttonTextSettings"
        :value="buttonText"
        @change="changeProp($event.target.value, 'buttonText')"
      />
      <settings-show-if :parentId="selectedGroup.key" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PanelTitle from '@forms/components/settings-panel/PanelTitle.vue';
import SettingsShowIf from '@forms/components/settings-panel/SettingsShowIf.vue';

export default {
  props: {
    accordeonItem: {
      type: String
    }
  },
  components: {
    PanelTitle,
    SettingsShowIf
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedGroup', 'requiredGroup']),
    repeatable() {
      if (this.selectedGroup && this.selectedGroup.schema) {
        return this.selectedGroup.schema.data.repeatable;
      }
      return false;
    },
    isNotDependencyQuestion() {
      if (this.selectedGroup && this.selectedGroup.schema) {
        return this.$store.getters['formBuilder/dependencyQuestionDataById'](this.selectedGroup.key);
      }
      return false;
    },
    groupTitle() {
      if (this.selectedGroup && this.selectedGroup.schema) {
        let groupTitle = this.selectedGroup.schema.title.replace(/<[^>]*>?/gm, '');
        return groupTitle;
      }
      return;
    },
    showTitle() {
      if (this.selectedGroup && this.selectedGroup.schema) {
        return this.selectedGroup.schema.data.showTitle;
      }
      return;
    },
    showDescription() {
      if (this.selectedGroup && this.selectedGroup.schema) {
        return this.selectedGroup.schema.data.showDescription;
      }
      return;
    },
    buttonText() {
      if (this.selectedGroup && this.selectedGroup.schema) {
        return this.selectedGroup.schema.data.buttonText;
      }
      return false;
    }
  },
  methods: {
    changeProp(value, type) {
      this.$store.commit('formBuilder/editGroupQuestionProp', {
        type: type,
        value: value
      });
      if (type === 'repeatable') {
        if (value) {
          this.$store.commit('formBuilder/removeGroupQuestionProp', 'maxItems');
        } else {
          this.$store.commit('formBuilder/editGroupQuestion', {
            type: 'maxItems',
            value: 1
          });
        }
      }
    },
    changeVisibility(value, type) {
      this.$store.commit('formBuilder/editGroupQuestionProp', {
        type: type,
        value: value
      });
    },
    changeRequired(value) {
      this.$store.commit('formBuilder/toggleRequiredGroup');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';

input {
  width: 100%;
  padding: 0;
  font-size: 14px;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-box-shadow: inset 0 -1px 0 #ddd;
  box-shadow: inset 0 -1px 0 #ddd;
  height: 40px;
  line-height: 40px;
  color: #606266;
}

input:focus {
  outline: none;
  box-shadow: inset 0 -2px 0 #d7e0e8 !important;
}

.settings-section {
  border: 1px solid rgba(2, 28, 44, 0.15);
  border-radius: 4px;

  label {
    display: block;
  }

  .content-wrap {
    padding: 24px;
  }
  .title-wrap {
    padding: 24px;
    border-bottom: 1px solid rgba(2, 28, 44, 0.15);
    display: flex;
    flex-direction: row;
    align-items: center;
    i {
      cursor: pointer;
      margin-left: auto;
    }
  }
}
</style>
