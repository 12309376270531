<template>
  <div>
    <div class="row">
      <label class="col-4">{{ $t('lp.learningPageLabel') }}</label>
      <div class="col-8">
        <div class="select-wrapper" :class="{ disabled: variant === 2 }" @click="variant !== 2 ? (open = !open) : ''">
          {{ max }}
          <i class="material-icons" v-if="!open">expand_more</i>
          <i class="material-icons" v-else>expand_less</i>
        </div>
        <div class="options" v-if="open">
          <div
            v-for="index in valuelist"
            :key="index"
            @click="
              max = index;
              open = false;
            "
          >
            {{ index }}
          </div>
        </div>
      </div>
    </div>
    <talmundo-switch
      id="showLabels"
      :label="$t('labels.settingLabels')"
      v-model="showLabels"
      @change="changeVisibility($event, 'showLabels')"
    />
    <div class="row">
      <variant-select :variants="ratingVariants" :selected="variant" @change="changeVariant"></variant-select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import range from 'lodash/range';
import VariantSelect from '@admin/shared/components/VariantSelect.vue';
import cloneDeep from 'lodash/cloneDeep';

export default {
  components: { VariantSelect },
  data() {
    return {
      maxRating: 10,
      open: false,
      ratingVariants: [
        {
          value: 0,
          label: this.$t('form.rating.numberScale'),
          img: require('../../images/questions/rating/rating-number.png'),
          imgSmall: require('../../images/questions/rating/rating-number.png')
        },
        {
          value: 1,
          label: this.$t('form.rating.starScale'),
          img: require('../../images/questions/rating/rating-star.png'),
          imgSmall: require('../../images/questions/rating/rating-star.png')
        },
        {
          value: 2,
          label: this.$t('form.rating.happyScale'),
          img: require('../../images/questions/rating/rating-happy.png'),
          imgSmall: require('../../images/questions/rating/rating-happy.png')
        }
      ]
    };
  },
  created() {
    this.checkSetLimitsDefaultOrHappy(this.selectedQuestion.schema.variant);
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedQuestion']),
    variant() {
      return cloneDeep(this.selectedQuestion.schema.variant);
    },
    valuelist() {
      return range(this.selectedQuestion.schema.min, this.maxRating + 1);
    },
    showLabels() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        return this.selectedQuestion.schema.data.showLabels;
      }
      return true;
    },
    max: {
      get: function() {
        if (!this.selectedQuestion) {
          return 5;
        }

        return this.selectedQuestion.schema.max;
      },
      set: function(value) {
        this.$store.commit('formBuilder/editFormRatingMax', {
          type: 'max',
          value: value
        });
      }
    }
  },
  methods: {
    changeVisibility(value, type) {
      this.$store.commit('formBuilder/editFormQuestion', {
        type: type,
        value: value
      });
    },
    setMaxRating(event) {
      this.$store.commit('formBuilder/editFormQuestion', {
        type: 'max',
        value: Number(event.target.value)
      });
    },
    changeVariant(variant) {
      this.$store.commit('formBuilder/editFormQuestionProp', { type: 'variant', value: variant });
      this.checkSetLimitsDefaultOrHappy(variant);
    },
    checkSetLimitsDefaultOrHappy(variant) {
      if (variant === 2) {
        this.setLimitsHappyScale();
      } else {
        this.setDefaultsScale();
      }
    },
    setLimitsHappyScale() {
      this.maxRating = 5;
      this.max = 5;
      this.open = false;
    },
    setDefaultsScale() {
      this.maxRating = 10;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
.select-wrapper {
  background-color: $color-tertiary;
  width: 100%;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  position: relative;
  padding-left: 8px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.material-icons {
  color: $color-midgreyblue;
  position: absolute;
  top: 8px;
  right: 8px;
}

label {
  color: $color-secondary;
  font-size: 12px;
  line-height: 40px;
}

.options {
  height: 80px;
  overflow-y: scroll;
  background-color: $color-tertiary;
  width: 100%;

  div {
    padding: 8px;
  }
}
</style>
