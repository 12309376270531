<template>
  <div>
    <talmundo-switch
      id="shortAnswer"
      :label="$t('form.shortAnswer')"
      v-model="getShortAnswer"
      @change="changeType($event, 'shortAnswer')"
    />
    <talmundo-switch
      id="requireValidation"
      :label="$t('form.requireValidation')"
      v-model="getRequireValidation"
      @change="changeType($event, 'requireValidation')"
    />
    <talmundo-input-number
      id="maxCharacters"
      :label="$t('form.maxChar')"
      v-model="getMaxCharacters"
      @change="changeType($event, 'maxCharacters')"
      :max="max"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      max: 120,
      open: false
    };
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedQuestion']),
    getPlaceholder() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        return this.selectedQuestion.schema.data.placeholder.replace(/&amp;/gi, '&').replace(/<\/?[^>]+(>|$)/g, '');
      }
      return true;
    },
    getShortAnswer() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        this.max = this.selectedQuestion.schema.data.shortAnswer ? 120 : 300;
        return this.selectedQuestion.schema.data.shortAnswer;
      }
      return true;
    },
    getMaxCharacters() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        return this.selectedQuestion.schema.data.maxCharacters;
      }
      return true;
    },
    getRequireValidation() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        return this.selectedQuestion.schema.data.requireValidation;
      }
      return true;
    }
  },
  methods: {
    changeType(value, type) {
      if (type === 'shortAnswer') {
        this.changeType(value ? 120 : 300, 'maxCharacters');
      }
      this.$store.commit('formBuilder/editFormQuestion', {
        type: type,
        value: value
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
.select-wrapper {
  background-color: $color-tertiary;
  width: 100%;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  position: relative;
  padding-left: 8px;
}

.material-icons {
  color: $color-midgreyblue;
  position: absolute;
  top: 8px;
  right: 8px;
}

label {
  color: $color-secondary;
  font-size: 12px;
  line-height: 40px;
}

.options {
  height: 80px;
  overflow-y: scroll;
  background-color: $color-tertiary;
  width: 100%;

  div {
    padding: 8px;
  }
}
</style>
