<template>
  <div class="settings-section" v-if="selectedQuestion">
    <div class="title-wrap">
      <panel-title v-html="questionTitle"></panel-title>
      <i @click="$emit('update', 'question')" class="material-icons">{{
        accordeonItem === 'question' ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
      }}</i>
    </div>
    <div class="content-wrap" v-if="accordeonItem === 'question'">
      <setting-label>{{ 'labels.addons' | translate }}</setting-label>
      <talmundo-switch
        v-if="!isDependencyQuestion && !isGdprQuestion"
        id="showRequired"
        :label="$t('form.required')"
        v-model="requiredQuestion"
        @change="changeRequired($event)"
      />
      <talmundo-switch
        v-if="!isGroup && !isGdprQuestion"
        id="showQuestionTitle"
        :label="$t('labels.settingTitle')"
        v-model="showTitle"
        @change="changeVisibility($event, 'showTitle')"
      />
      <talmundo-switch
        v-if="!isGroup && !isGdprQuestion"
        id="showQuestionDescription"
        :label="$t('labels.settingDescription')"
        v-model="showDescription"
        @change="changeVisibility($event, 'showDescription')"
      />
      <settings-input-file v-if="isQuestionFile"></settings-input-file>
      <settings-open-question v-if="isOpenAnswer"></settings-open-question>
      <settings-choice v-if="isChoiceQuestion"></settings-choice>
      <settings-rating v-if="isRatingQuestion"></settings-rating>
      <settings-address v-if="isAddressQuestion"></settings-address>
      <settings-placeholder v-if="hasPlaceholder"></settings-placeholder>
      <settings-reg-exp v-if="hasRegExp"></settings-reg-exp>
      <settings-number v-if="hasNumberField"></settings-number>
      <settings-gdpr v-if="isGdprQuestion"></settings-gdpr>
      <settings-show-if v-if="isNotGroupQuestion && !isGdprQuestion" :parentId="questionKey" />
    </div>
  </div>
</template>

<script>
import PanelTitle from '@forms/components/settings-panel/PanelTitle.vue';
import SettingLabel from '@forms/components/settings-panel/SettingLabel.vue';
import SettingsShowIf from '@forms/components/settings-panel/SettingsShowIf.vue';
import SettingsRating from '@forms/components/settings-panel/SettingsRating.vue';
import SettingsGroup from '@forms/components/settings-panel/SettingsGroup.vue';
import SettingsChoice from '@forms/components/settings-panel/SettingsChoice.vue';
import SettingsPlaceholder from '@forms/components/settings-panel/SettingsPlaceholder.vue';
import SettingsRegExp from '@forms/components/settings-panel/SettingsRegExp.vue';
import SettingsNumber from '@forms/components/settings-panel/SettingsNumber.vue';
import SettingsOpenQuestion from '@forms/components/settings-panel/SettingsOpenQuestion.vue';
import SettingsInputFile from '@forms/components/settings-panel/SettingsInputFile.vue';
import SettingsAddress from '@forms/components/settings-panel/SettingsAddress.vue';
import SettingsGdpr from '@forms/components/settings-panel/SettingsGdpr.vue';

import fieldTypes from '@forms/const/fieldTypes.js';
import cloneDeep from 'lodash/cloneDeep';

import { mapGetters } from 'vuex';

export default {
  props: {
    accordeonItem: {
      type: String
    }
  },
  components: {
    PanelTitle,
    SettingLabel,
    SettingsRating,
    SettingsGroup,
    SettingsChoice,
    SettingsShowIf,
    SettingsInputFile,
    SettingsOpenQuestion,
    SettingsPlaceholder,
    SettingsRegExp,
    SettingsAddress,
    SettingsGdpr,
    SettingsNumber
  },
  methods: {
    changeVisibility(value, type) {
      this.$store.commit('formBuilder/editFormQuestion', {
        type: type,
        value: value
      });
    },
    changeRequired(value) {
      const dependentGroup = this.$store.getters['formBuilder/dependencyQuestionDataById'](this.selectedGroup.key);
      this.$store.commit('formBuilder/toggleRequiredQuestion', dependentGroup);
    }
  },
  computed: {
    ...mapGetters('formBuilder', [
      'selectedItem',
      'uiSchemaBuilder',
      'selectedSection',
      'selectedQuestion',
      'selectedGroup',
      'requiredQuestion',
      'schema'
    ]),
    questionTitle() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        let questionTitle = this.selectedQuestion.schema.title.replace(/<[^>]*>?/gm, '');
        return questionTitle;
      }
      return;
    },
    showTitle() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        return this.selectedQuestion.schema.data.showTitle;
      }
      return;
    },
    showDescription() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        return this.selectedQuestion.schema.data.showDescription;
      }
      return;
    },
    questionKey() {
      if (this.selectedQuestion && this.selectedQuestion.key) {
        return this.selectedQuestion.key;
      }
      return '';
    },
    isRatingQuestion() {
      return (
        this.selectedQuestion &&
        this.selectedQuestion.schema &&
        this.selectedQuestion.schema['field-type'] === fieldTypes.questionRating
      );
    },
    isChoiceQuestion() {
      return (
        this.selectedQuestion &&
        this.selectedQuestion.schema &&
        this.selectedQuestion.schema['field-type'] === fieldTypes.questionChoice
      );
    },
    hasPlaceholder() {
      return (
        this.selectedQuestion &&
        this.selectedQuestion.schema &&
        this.selectedQuestion.schema.data.hasOwnProperty('placeholder')
      );
    },
    hasRegExp() {
      return (
        this.selectedQuestion &&
        this.selectedQuestion.schema &&
        this.selectedQuestion.schema['field-type'] === fieldTypes.questionRegExp
      );
    },
    hasNumberField() {
      return (
        this.selectedQuestion &&
        this.selectedQuestion.schema &&
        this.selectedQuestion.schema['field-type'] === fieldTypes.questionNumber
      );
    },
    isOpenAnswer() {
      return (
        this.selectedQuestion &&
        this.selectedQuestion.schema &&
        this.selectedQuestion.schema['field-type'] === fieldTypes.questionOpenAnswer
      );
    },
    isGroup() {
      return (
        this.selectedQuestion &&
        this.selectedQuestion.schema &&
        this.selectedQuestion.schema['field-type'] === fieldTypes.group
      );
    },
    isQuestionFile() {
      return (
        this.selectedQuestion &&
        this.selectedQuestion.schema &&
        this.selectedQuestion.schema['field-type'] === fieldTypes.questionFile
      );
    },
    isAddressQuestion() {
      return (
        this.selectedQuestion &&
        this.selectedQuestion.schema &&
        this.selectedQuestion.schema['field-type'] === fieldTypes.questionAddress
      );
    },
    isGdprQuestion() {
      return (
        this.selectedQuestion &&
        this.selectedQuestion.schema &&
        this.selectedQuestion.schema['field-type'] === fieldTypes.questionGDPR
      );
    },
    isNotGroupQuestion() {
      if (this.selectedQuestion && this.selectedQuestion.key) {
        return !this.selectedQuestion.key.includes('form-group');
      }
      return false;
    },
    isDependencyQuestion() {
      if (this.selectedQuestion && this.selectedQuestion.key) {
        const dependencyQuestion = this.$store.getters['formBuilder/dependencyQuestionDataById'](
          this.selectedQuestion.key
        );
        if (dependencyQuestion && dependencyQuestion.length > 0) {
          return true;
        }
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.settings-section {
  border: 1px solid rgba(2, 28, 44, 0.15);
  border-radius: 4px;

  label {
    display: block;
  }

  .content-wrap {
    padding: 24px;
  }
  .title-wrap {
    padding: 24px;
    border-bottom: 1px solid rgba(2, 28, 44, 0.15);
    display: flex;
    flex-direction: row;
    align-items: center;
    i {
      cursor: pointer;
      margin-left: auto;
    }
  }
}
</style>
