<template>
  <div>
    <talmundo-switch
      id="address2"
      :label="$t('forms.displayAddress2')"
      v-model="getDisplayAddress2"
      @change="changeType($event, 'displayAddress2')"
    />
    <talmundo-switch
      id="adminArea"
      :label="$t('forms.displayProvince')"
      v-model="getDisplayAdminArea"
      @change="changeType($event, 'displayAdminArea')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('formBuilder', ['selectedQuestion']),
    getDisplayAdminArea() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        return this.selectedQuestion.schema.data.displayAdminArea;
      }
      return true;
    },
    getDisplayAddress2() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        return this.selectedQuestion.schema.data.displayAddress2;
      }
      return true;
    }
  },
  methods: {
    changeType(value, type) {
      this.$store.commit('formBuilder/editFormQuestion', {
        type: type,
        value: value
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
.select-wrapper {
  background-color: $color-tertiary;
  width: 100%;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  position: relative;
  padding-left: 8px;
}

.material-icons {
  color: $color-midgreyblue;
  position: absolute;
  top: 8px;
  right: 8px;
}

label {
  color: $color-secondary;
  font-size: 12px;
  line-height: 40px;
}

.options {
  height: 80px;
  overflow-y: scroll;
  background-color: $color-tertiary;
  width: 100%;

  div {
    padding: 8px;
  }
}
</style>
