<template>
  <div>
    <talmundo-switch id="showIfSwitch" :label="$t('form.showIf')" v-model="showIf" @change="updateShowIf($event)" />

    <div v-if="showIf">
      <div v-for="(ifValue, index) in ifValues" :key="index">
        <div class="editor-condition-container">
          <div class="editor-condition-title">
            <span>{{ $t('form.condition') }} {{ index + 1 }}</span>
            <i class="material-icons more_vert" @click="removeCondition(index)" v-if="ifValues.length > 1">delete</i>
          </div>
          <talmundo-select
            id="selectSection"
            v-model="ifValue.section"
            :placeholder="$t('form.selectSection')"
            :items="getItems(ifValue)['sections']"
            @change="updateSelect($event, index, 'section')"
          />
          <talmundo-select
            id="selectQuestion"
            v-model="ifValue.question"
            :placeholder="$t('form.selectQuestion')"
            :disabled="getItems(ifValue)['questions'] && getItems(ifValue)['questions'].length === 0"
            :items="getItems(ifValue)['questions']"
            @change="updateSelect($event, index, 'question')"
          />

          <!-- <div v-if="getItems(ifValue)['questionType']"> -->
          <talmundo-select
            id="selectQuestion"
            v-model="ifValue.value"
            :placeholder="$t('form.selectValue')"
            :items="getItems(ifValue)['values']"
            :disabled="getItems(ifValue)['values'] && getItems(ifValue)['values'].length === 0"
            @change="updateSelect($event, index, 'value')"
          />
          <!-- <input
          v-else
          id="selectQuestion"
          v-model="ifValue.value"
          placeholder="Select value"
          class="form-control"
          @blur="updateSelect($event.target.value,index,'value')"
          />-->
          <!-- </div> -->
        </div>
      </div>
      <div class="add-condition-container" @click="addCondition()">
        <i class="material-icons">add</i> {{ $t('form.addCondition') }}
      </div>
    </div>
  </div>
</template>

<script>
import fieldTypes from '@forms/const/fieldTypes.js';
import { uuid } from 'vue-uuid';
import { mapGetters } from 'vuex';
export default {
  props: {
    parentId: {
      type: String
    }
  },
  mounted() {
    this.updateIfValueData();
  },
  watch: {
    parentId() {
      this.updateIfValueData();
    }
  },
  data() {
    return {
      showIf: false,
      ifValues: [
        {
          section: '',
          question: '',
          value: ''
        }
      ]
    };
  },
  methods: {
    addCondition() {
      this.ifValues.push({
        section: '',
        question: '',
        value: ''
      });
    },
    removeCondition(index) {
      if (this.ifValues[index].$id) {
        this.removeIfElement(this.ifValues[index].$id);
      }
      this.ifValues.splice(index, 1);
    },
    updateIfValueData() {
      if (this.parentId) {
        const ifValues = this.$store.getters['formBuilder/dependencyQuestionDataById'](this.parentId);
        if (ifValues && ifValues.length > 0) {
          this.showIf = true;
          this.ifValues = ifValues;
        } else {
          this.showIf = false;
          this.ifValues = [
            {
              section: '',
              question: '',
              value: ''
            }
          ];
        }
      }
    },
    updateShowIf(event) {
      this.showIf = event;
      if (!event) {
        this.ifValues.forEach((e) => {
          if (e.$id) {
            this.removeIfElement(e.$id);
          }
        });
        this.ifValue = [
          {
            section: '',
            question: '',
            value: ''
          }
        ];
      }
    },
    updateSelect(event, index, type) {
      this.ifValues[index][type] = event;
      if (this.ifValues[index].$id) {
        this.removeIfElement(this.ifValues[index].$id);
        delete this.ifValues[index].$id;
      }
      if (type === 'section') {
        this.ifValues[index].question = this.ifValues[index].value = '';
      }
      if (type === 'question') {
        this.ifValues[index].value = '';
      }
      if (type === 'value') {
        this.ifValues[index].$id = uuid.v4();
        this.addIfElement(index);
      }
    },
    removeIfElement(id) {
      this.$store.commit('formBuilder/removeIfElement', {
        $id: id
      });
    },
    addIfElement(index) {
      this.$store.commit('formBuilder/addIfElement', {
        parentId: this.parentId,
        value: this.ifValues[index]
      });
    },
    getCleanValue(string) {
      return string
        .replace(/&amp;/gi, '&')
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replace('&lt;', '<')
        .replace('&gt;', '>');
    },
    getItems(ifValue) {
      return {
        sections: Object.keys(this.schema.properties)
          .sort((a, b) => this.schema.properties[a].data.order - this.schema.properties[b].data.order)
          .filter((e) => e !== this.parentId)
          .map((e) => {
            return {
              value: e,
              label: this.getCleanValue(this.schema.properties[e].title)
            };
          }),
        questions: ifValue.section
          ? Object.keys(this.schema.properties[ifValue.section].properties)
              .sort(
                (a, b) =>
                  this.schema.properties[ifValue.section].properties[a].data.order -
                  this.schema.properties[ifValue.section].properties[b].data.order
              )
              .filter(
                (e) =>
                  e !== this.parentId &&
                  this.schema.properties[ifValue.section].properties[e]['field-type'] === fieldTypes.questionChoice
              )
              .map((e) => {
                return {
                  value: e,
                  label: this.getCleanValue(this.schema.properties[ifValue.section].properties[e].title)
                };
              })
          : [],
        values:
          ifValue.question &&
          this.schema.properties[ifValue.section].properties[ifValue.question] &&
          this.schema.properties[ifValue.section].properties[ifValue.question].enum
            ? this.schema.properties[ifValue.section].properties[ifValue.question].enum.map((e) => {
                return {
                  value: e,
                  label: this.getCleanValue(e)
                };
              })
            : []
      };
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['schema'])
  }
};
</script>

<style lang="scss" scoped>
.editor-condition-container {
  padding: 8px;
  border: solid 1px #ddd;
  border-radius: 5px;
  margin-bottom: 8px;
  .editor-condition-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    span {
      font-family: 'Talmundo';
    }
    i {
      margin-left: auto;
      color: #ccc;
      cursor: pointer;
      font-size: 20px;
    }
  }
}
.add-condition-container {
  font-family: 'Talmundo';
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  cursor: pointer;
  i {
    font-size: 20px;
    color: #ccc;
  }
}
</style>
