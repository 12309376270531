<template>
  <div class="settings-section" v-if="sectionTitle">
    <div class="title-wrap">
      <panel-title v-html="sectionTitle"></panel-title>
      <i @click="$emit('update', 'section')" class="material-icons">{{
        accordeonItem === 'section' ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
      }}</i>
    </div>
    <hr />
    <div class="content-wrap" v-if="accordeonItem === 'section'">
      <setting-label>{{ 'labels.addons' | translate }}</setting-label>
      <talmundo-switch
        id="showTitle"
        :label="$t('labels.settingTitle')"
        v-model="showTitle"
        @change="changeVisibility($event, 'showTitle')"
      />
      <talmundo-switch
        id="showDescription"
        :label="$t('labels.settingDescription')"
        v-model="showDescription"
        @change="changeVisibility($event, 'showDescription')"
      />
    </div>
  </div>
</template>

<script>
import PanelTitle from '@forms/components/settings-panel/PanelTitle.vue';
import SettingLabel from '@forms/components/settings-panel/SettingLabel.vue';

import { mapGetters } from 'vuex';

export default {
  props: {
    accordeonItem: {
      type: String
    }
  },
  components: {
    PanelTitle,
    SettingLabel
  },
  methods: {
    changeVisibility(value, type) {
      this.$store.commit('formBuilder/editFormSectionProp', {
        type: type,
        value: value
      });
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedItem', 'uiSchemaBuilder', 'selectedSection']),
    sectionTitle() {
      if (this.selectedSection && this.selectedSection.schema) {
        return this.selectedSection.schema.title;
      }
      return;
    },
    showTitle() {
      if (this.selectedSection && this.selectedSection.schema) {
        return this.selectedSection.schema.data.showTitle;
      }
      return true;
    },
    showDescription() {
      if (this.selectedSection && this.selectedSection.schema) {
        return this.selectedSection.schema.data.showDescription;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.settings-section {
  border: 1px solid rgba(2, 28, 44, 0.15);
  border-radius: 4px;

  label {
    display: block;
  }

  .content-wrap {
    padding: 24px;
  }

  .title-wrap {
    padding: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    i {
      cursor: pointer;
      margin-left: auto;
    }
  }

  hr {
    margin: 0;
  }
}
</style>
